import React, { useState, useEffect } from "react";
import { Box } from "primitives";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import { Link } from "react-router-dom";
import { PaginationSimple } from "app/shared";
import { DeleteButton, ResetButton } from "components";
import { useSelector, useDispatch } from "react-redux";
import { BookCandidateContainer } from "./BookCandidateContainer";
import { loadState, clearState } from "app/passageBooker/actions";
export const CandidatesTable = () => {
  const {
    data: _candidates,
    pagination,
    lastFetchedAt
  } = useSelector((s: any) => s.passageBooker.candidates);
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(20);
  const [cachedCandidates, setCachedCandidates] = useState<any>({});
  const [candidates, setCandidates] = useState<any>(cachedCandidates);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(pagination?.total);
  console.log(
    "TURBO ~ CandidatesTable:",
    candidates,
    lastFetchedAt,
    cachedCandidates
  );

  useEffect(() => {
    setCachedCandidates(loadState("CANDIDATES"));
  }, []);

  return (
    <>
      <Box
        data-testid="passage-booker-list"
        className="passage-booker-list"
        mx={3}
      >
        <Table>
          <TableHead>
            <TableRow bg="fill.0">
              <TableCell width="10">Id</TableCell>
              <TableCell width="auto">Start</TableCell>
              <TableCell width="auto">End</TableCell>
              <TableCell width="auto">GS</TableCell>
              <TableCell width="auto">Max Elevation</TableCell>
              <TableCell width="auto">Satellite</TableCell>
              <TableCell width="auto">Provider</TableCell>
              <TableCell width="auto">Editable</TableCell>
              <TableCell width="auto">Priority</TableCell>
              <TableCell width="auto">Optimal</TableCell>
              <TableCell width="auto">Status</TableCell>
              <TableCell width="auto">
                {lastFetchedAt ||
                  (cachedCandidates.lastFetchedAt &&
                    `Last Fetched at ${
                      lastFetchedAt || cachedCandidates.lastFetchedAt
                    }`)}
              </TableCell>
              <TableCell width="auto">
                {candidates?.length ? (
                  <ResetButton
                    onClick={() => {
                      setCachedCandidates({});
                      return clearState("CANDIDATES");
                    }}
                    mx={1}
                  >
                    Clear
                  </ResetButton>
                ) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <CandidatesLoading
              candidates={candidates}
              pagination={pagination}
            />
            {Array.isArray(candidates) &&
              candidates.map((candidate: any, idx: number) => {
                return (
                  <TableRow key={candidate.passageID}>
                    <TableCell width="2%">
                      <Link
                        to={{ pathname: `/edit-passage`, state: { candidate } }}
                      ></Link>
                      {candidate.passageID}
                    </TableCell>
                    <TableCell width="1%">
                      {new Date(candidate.aos).toLocaleString()}
                    </TableCell>
                    <TableCell width="auto">
                      {new Date(candidate.los).toLocaleString()}
                    </TableCell>
                    <TableCell>{candidate.groundStationName}</TableCell>
                    <TableCell>{candidate.maxElevation}</TableCell>
                    <TableCell align="center">
                      {candidate.satelliteID}
                    </TableCell>
                    <TableCell align="center" width="4%">
                      {candidate.leafSpacePassageID?.leafSpacePassageID?.toUpperCase()}
                    </TableCell>
                    <TableCell align="center">
                      {candidate.is_editable}
                    </TableCell>
                    <TableCell align="center">{candidate.priority}</TableCell>
                    <TableCell align="center">{candidate.is_optimal}</TableCell>
                    <TableCell align="center">
                      {candidate.status?.toUpperCase()}
                    </TableCell>
                    <TableCell>
                      <BookCandidateContainer
                        candidate={candidate}
                      ></BookCandidateContainer>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <DeleteButton>CANCEL</DeleteButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <PaginationSimple
          total={total}
          page={page + 1}
          pageSize={pageSize}
          nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
          previousPageUrl={page - 1}
          onChange={(newPage: string | number) => {
            setPage(Number(newPage));
            setPageSize(pageSize);
          }}
          onPageSizeChange={(newPageSize: number) => {
            setPage(0);
            setPageSize(Number(newPageSize));
          }}
        />
      </Box>
    </>
  );
};

export default function CandidatesLoading({ candidates, pagination }: any) {
  //add && isNaN(pagination?.total) check when BE returns paginated candidates
  return candidates?.length === 0 ? (
    <Box m={4}>No candidates found....</Box>
  ) : null;
}
