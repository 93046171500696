import React, { useEffect, useCallback } from "react";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { CancelButton, EditButton, SaveButton } from "components";
import { connect } from "react-redux";
import {
  Flex,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "primitives";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "app/shared";
import { fetchGroundStationsAction as gs } from "app/groundStation/actions";
import { bookingOnePassage } from "app/passageBooker/actions";
import {
  bookSchemaEdit,
  bookSchemaUISchemEdit
} from "app/passageBooker/models/schema/bookSchema";

interface BookCandidateContainerProps {
  closeModal?: any;
  candidate?: any;
}

export const BookCandidate = ({
  candidate,
  ...props
}: BookCandidateContainerProps) => {
  const dispatch = useDispatch();
  const presets = [{ id: 1, name: "DEFAULT" }];
  const bookFormData = {};
  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any,
    onToggle: any
  ) => {
    e.preventDefault();
    const bookingPayload = [
      {
        providerID: candidate.providerID,
        satelliteID: candidate.satelliteID,
        passageID: candidate.passageID,
        groundStationName: candidate.groundStationName
      }
    ];
    const result = await dispatch(bookingOnePassage(bookingPayload));
    console.log("TURBO ~ result:", result);
    onToggle();
  };

  const validator = (formData: any, errors: any) => {
    console.log("ADD custom validations", formData);
    return errors;
  };

  const transformData = (formData: any) => {
    return formData;
  };

  const onChange = useCallback((form) => {
    transformData(form.formData);
  }, []);

  const formData = {};

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <EditButton onClick={onToggle}>BOOK</EditButton>
          <Dialog
            open={toggled}
            maxWidth="lg"
            data-testid="passage-booker-edit"
          >
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>
                      Book candidate with id: {candidate?.passageID}?
                    </DialogTitle>
                    <DialogContent>
                      <Flex alignItems="center">
                        <Form
                          id="passage-booker"
                          validate={(formData: any, errors: any) =>
                            validator(formData, errors)
                          }
                          formData={bookFormData}
                          schema={bookSchemaEdit(presets)}
                          uiSchema={bookSchemaUISchemEdit}
                          disabled={loading}
                          onSubmit={(
                            form: { formData: any },
                            e: React.FormEvent<HTMLInputElement>
                          ) => {
                            return submit(e, form.formData, onToggle);
                          }}
                          onChange={(form: any) => onChange(form)}
                        >
                          <></>
                        </Form>
                      </Flex>
                    </DialogContent>
                    <DialogActions>
                      <CancelButton onClick={onToggle} disabled={loading}>
                        Cancel
                      </CancelButton>
                      <SaveButton
                        form="passage-booker"
                        type="submit"
                        ml={2}
                        disabled={loading}
                      >
                        BOOK PASSAGE
                      </SaveButton>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};

const mapDispatchToProps = () => ({});

export const BookCandidateContainer = connect(
  null,
  mapDispatchToProps
)(BookCandidate);
