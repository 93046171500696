import {
  FETCH_PASSAGE_ERROR,
  FETCH_PASSAGE_START,
  FETCH_PASSAGE_SUCCESS,
  FETCH_BOOKER_CONFIGS_SUCCESS,
  FETCH_PASSAGE_CANDIDATES_SUCCESS,
  CREATE_BOOK_REQUEST_SUCCESS
} from "../models/actions";

const initialState: any = {
  candidates: [],
  bookerConfigs: [],
  bookedPassages: [],
  pagination: []
};

export const passageBookerReducer = (
  state = initialState,
  action: { type: any; payload: any; pagination: any }
) => {
  switch (action.type) {
    case FETCH_PASSAGE_ERROR:
    case FETCH_PASSAGE_START:
      return initialState;
    case FETCH_PASSAGE_SUCCESS:
      return { ...initialState, candidates: action.payload };
    case FETCH_BOOKER_CONFIGS_SUCCESS:
      return {
        ...initialState,
        bookerConfigs: action.payload
      };
    case FETCH_PASSAGE_CANDIDATES_SUCCESS:
      return { ...initialState, candidates: action.payload };
    case CREATE_BOOK_REQUEST_SUCCESS:
      return { ...initialState, bookedPassages: action.payload };
    default:
      return state;
  }
};
